import { Step1, Step2, Step3 } from 'assets/icons'

const AppointmentSteps = () => {
	return (
		<section className="py-16 bg-[#e9f1f8] relative">
			<div className="text-center mb-12">
				<h2 className="text-3xl md:text-5xl font-bold text-[#2c2d6a]">
					Book Appointments in 3 Steps
				</h2>
				<p className="text-sm md:text-md text-gray-600 mt-4">
					With lots of unique blocks, you can easily book appointments without any hassle.
				</p>
			</div>

			<div className="relative z-10 flex flex-col items-center space-y-16 md:flex-row md:space-y-0 md:space-x-32 justify-center">
				<div className="flex flex-col items-center text-center w-full md:w-64">
					<img src={Step1} alt="Step 1" className="w-48 h-32 md:w-64 md:h-48 object-contain" />
					<h3 className="text-lg md:text-xl font-bold">Customise to Find</h3>
					<p className="text-gray-600 mt-2 max-w-xs text-sm md:text-base">
						Select the industry, choose the specific service, and specify your location to find the
						most relevant options nearby.
					</p>
				</div>

				<div className="flex flex-col items-center text-center w-full md:w-64">
					<img src={Step2} alt="Step 2" className="w-48 h-32 md:w-64 md:h-48 object-contain" />
					<h3 className="text-lg md:text-xl font-bold">Search for a Business</h3>
					<p className="text-gray-600 mt-2 max-w-xs text-sm md:text-base">
						Type in the company name if you have one in mind or simply search to discover the best
						businesses available.
					</p>
				</div>

				<div className="flex flex-col items-center text-center w-full md:w-64">
					<img src={Step3} alt="Step 3" className="w-48 h-32 md:w-64 md:h-48 object-contain" />
					<h3 className="text-lg md:text-xl font-bold">Book Appointment</h3>
					<p className="text-gray-600 mt-2 max-w-xs text-sm md:text-base">
						Browse through our curated list of top businesses. Select your preferred time slot and
						confirm your booking in just a few clicks.
					</p>
				</div>
			</div>

			<div className="flex justify-center mt-12 relative z-10">
				<button className="bg-[#5964D3] text-white px-6 py-2 md:px-8 md:py-3 rounded-full hover:bg-[#7D8AE6] focus:outline-none">
					Book Now
				</button>
			</div>
		</section>
	)
}

export default AppointmentSteps
