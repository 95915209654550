import { FaRegCalendarCheck } from 'react-icons/fa'

interface BlogCardProps {
	date: string
	readTime: string
	imageUrl: string
	content: string
}

const BlogCard = ({ date, readTime, imageUrl, content }: BlogCardProps) => {
	return (
		<div className="relative bg-[#E6EEF7] rounded-2xl shadow-lg w-96 h-[500px] overflow-hidden">
			<img src={imageUrl} alt="Blog image" className="w-full h-full object-cover" />
			<div className="absolute bottom-5 left-5 right-5 flex items-stretch">
				<div className="w-3 bg-[#b790d9]"></div>
				<div className="bg-[#2a1f3a] bg-opacity-90 text-white p-4 flex-1 ml-6 rounded-lg">
					<div className="flex items-center text-sm mb-4">
						<FaRegCalendarCheck />
						<span className="ml-2">{date}</span>
						<span className="ml-2 text-gray-400">•</span>
						<span className="ml-2">{readTime}</span>
					</div>
					<p className="text-md">{content}</p>
				</div>
			</div>
		</div>
	)
}

export default BlogCard
