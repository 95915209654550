import { Provider1, Provider2, Provider3, Provider4, Provider5, Provider6 } from 'assets/icons'
import ProviderCard from './provider-card'

const ProvidersSection = () => {
	const providers = [
		{
			imageUrl: Provider1,
			title: 'Xenia Kosmetikstudio',
			description: 'Dein Kosmetikstudio in Waldshut.Seit nun fast 10 Jahren sind wir für unsere...',
			category: 'Makeup',
			rating: 5
		},
		{
			imageUrl: Provider2,
			title: "Gunjan's Clinic",
			description: 'Dein Kosmetikstudio in Waldshut.Seit nun fast 10 Jahren sind wir für unsere...',
			category: 'Therapy center',
			rating: 5
		},
		{
			imageUrl: Provider3,
			title: 'Premium Salon',
			description: 'Dein Kosmetikstudio in Waldshut.Seit nun fast 10 Jahren sind wir für unsere...',
			category: 'Makeup',
			rating: 5
		},
		{
			imageUrl: Provider4,
			title: 'Radiance hair & Skin',
			description: 'Dein Kosmetikstudio in Waldshut.Seit nun fast 10 Jahren sind wir für unsere...',
			category: 'Hair Salon',
			rating: 5
		},
		{
			imageUrl: Provider5,
			title: 'XO Studio',
			description: 'Dein Kosmetikstudio in Waldshut.Seit nun fast 10 Jahren sind wir für unsere...',
			category: 'Makeup',
			rating: 5
		},
		{
			imageUrl: Provider6,
			title: 'Nail me - UP',
			description: 'Dein Kosmetikstudio in Waldshut.Seit nun fast 10 Jahren sind wir für unsere...',
			category: 'Therapy center',
			rating: 5
		}
	]

	return (
		<section className="py-12 px-4">
			<div className="text-center mb-10">
				<p className="text-[#e71d73] text-sm font-bold italic mb-4">TRUSTED BY THOUSANDS</p>
				<h2 className="text-3xl md:text-5xl font-bold text-[#2c2d6a] mb-4">
					Most Popular Providers
				</h2>
				<p className="text-sm md:text-md text-gray-600 mb-10">
					Top providers, easy booking, exceptional service
				</p>
			</div>

			<div className="grid grid-cols-1 gap-y-10 gap-x-24 md:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto">
				{providers.map((provider, index) => (
					<ProviderCard
						key={index}
						imageUrl={provider.imageUrl}
						title={provider.title}
						description={provider.description}
						category={provider.category}
						rating={provider.rating}
					/>
				))}
			</div>
		</section>
	)
}

export default ProvidersSection
