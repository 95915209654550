import { useState } from 'react'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa'

interface FAQItem {
	question: string
	answer: string
}

const faqData: FAQItem[] = [
	{
		question: 'Can I reschedule or cancel my appointment?',
		answer:
			'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sunt officiis facilis consequuntur blanditiis dolorum consequatur praesentium officia, saepe eum, ea amet optio. Cum quaerat voluptatibus voluptatem iusto blanditiis modi veritatis dolores ipsam, adipisci ipsum molestias tenetur eum ad odio itaque maiores velit vel natus iure nesciunt? Natus consequuntur obcaecati voluptate.'
	},
	{
		question: 'How can I contact a business before booking?',
		answer:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam voluptatibus esse numquam sint error, dolor saepe maxime dolore deleniti non aperiam voluptatem quas architecto distinctio.'
	},
	{
		question: 'How do I book an appointment?',
		answer:
			"Find the business you're interested in, click on their profile to view available services and time slots. Select a convenient time, fill in your details, and confirm your booking. You’ll receive a confirmation email with all the details."
	},
	{
		question: 'Is my payment information secure?',
		answer:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis veritatis quibusdam nam quas eum.'
	}
]

export const FAQ = () => {
	const [activeIndex, setActiveIndex] = useState<number | null>(null)

	const toggleFAQ = (index: number) => {
		setActiveIndex(activeIndex === index ? null : index)
	}

	return (
		<div className="w-full max-w-4xl mx-auto p-6">
			<h2 className="text-3xl font-bold text-center mb-10">We are always here for your backup</h2>
			<div className="space-y-4">
				{faqData.map((faq, index) => (
					<div key={index} className="border-b border-gray-300 pb-4">
						<div
							className="flex justify-between items-center cursor-pointer"
							onClick={() => toggleFAQ(index)}>
							<div className="flex items-center space-x-10 mt-4 mb-4">
								<span className="text-indigo-600 font-bold text-xl">{`0${index + 1}`}</span>
								<span
									className={`${activeIndex === index ? 'text-indigo-600' : 'text-black'} text-xl font-semibold`}>
									{faq.question}
								</span>
							</div>
							<span>
								{activeIndex === index ? (
									<FaChevronDown className="text-indigo-600" />
								) : (
									<FaChevronRight className="text-gray-400" />
								)}
							</span>
						</div>
						{activeIndex === index && faq.answer && (
							<div className="w-[75%] mb-4 ml-16 text-gray-500 text-md">{faq.answer}</div>
						)}
					</div>
				))}
			</div>
		</div>
	)
}
