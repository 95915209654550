import { CircleCheckIcon, CircleCurrentIcon, CircleIcon } from 'assets/icons'
import { ProgressBar as Progress } from 'react-step-progress-bar'
import { ProgressBarProps } from 'react-toastify/dist/components'

import clsx from 'clsx'
import { Step } from 'components/progress-bar/step'
import useIsMobile from 'hooks/useIsMobile'

interface ProgressBarCustomProps extends Partial<ProgressBarProps> {
	totalSteps: number
	currentStep: number
	stepNames: string[]
}

export const ProgressBar = ({ totalSteps, currentStep, stepNames }: ProgressBarCustomProps) => {
	const isMobile = useIsMobile()
	return (
		<Progress
			filledBackground="#5964D3"
			height={2}
			width={isMobile ? 320 : 630}
			percent={(currentStep / (totalSteps - 1)) * 100}>
			{Array.from({ length: totalSteps }, (_, index) => (
				<Step transition="scale" key={index}>
					{({ accomplished }: any) => (
						<div className="relative flex flex-col items-center justify-center">
							{index === currentStep ? (
								<img src={CircleCurrentIcon} alt="current" className="max-lg:w-8 max-lg:h-8" />
							) : accomplished ? (
								<img src={CircleCheckIcon} alt="check" className="max-lg:w-8 max-lg:h-8" />
							) : (
								<img src={CircleIcon} alt="normal" className="max-lg:w-8 max-lg:h-8" />
							)}
							<span
								className={clsx(
									'absolute text-center top-10 max-lg:text-xs lg:whitespace-nowrap',
									index === currentStep ? 'text-primary-light' : 'text-[#7F9AB2]'
								)}>
								{stepNames[index]}
							</span>
						</div>
					)}
				</Step>
			))}
		</Progress>
	)
}
