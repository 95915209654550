import { AppleAppStore, GooglePlayStore } from 'assets/icons'

const PromotionSection = () => {
	return (
		<section className="flex flex-col gap-6 py-12 px-4 md:px-0 md:flex-row md:mx-20 md:gap-8 md:justify-center md:items-center">
			{/* First Card */}
			<div className="bg-gradient-to-bl from-[#38a0ed] to-[#e6f3fd] rounded-2xl p-6 md:p-8 text-center md:text-left w-full md:w-[500px] lg:w-[700px] flex flex-col justify-around h-auto md:h-[400px] lg:h-[450px]">
				<div>
					<h2 className="text-3xl md:text-5xl font-bold text-[#2c2d6a] mb-4">
						Grow your Business with Us
					</h2>
					<p className="text-md md:text-lg text-gray-700 mb-6">
						Register your business and make it easy for customers to book appointments. Gain more
						visibility and manage your bookings effortlessly.
					</p>
				</div>
				<div className="flex justify-center">
					<button className="bg-[#5964D3] text-white text-lg py-3 px-10 rounded-lg hover:bg-[#7D8AE6] focus:outline-none">
						Register Business
					</button>
				</div>
			</div>

			{/* Second Card */}
			<div className="bg-gradient-to-bl from-[#f03790] to-[#fee7f2] rounded-2xl p-6 md:p-8 text-center md:text-left w-full md:w-[500px] lg:w-[700px] flex flex-col justify-around h-auto md:h-[400px] lg:h-[450px]">
				<div>
					<h2 className="text-3xl md:text-5xl font-bold text-[#2c2d6a] mb-4">
						Download & Discover our Booking App
					</h2>
					<p className="text-md md:text-lg text-gray-700 mb-6">
						Book unforgettable beauty and wellness experiences with the Styling corner mobile app -
						The best way to discover top-rated salons and spas.
					</p>
				</div>
				<div className="flex justify-center items-center gap-4">
					<img src={GooglePlayStore} alt="Google Play" className="w-36 h-12 object-contain" />
					<img src={AppleAppStore} alt="App Store" className="w-36 h-11 rounded-md" />
				</div>
			</div>
		</section>
	)
}

export default PromotionSection
