import { Timestamp } from 'firebase/firestore'
import { DateTime, Duration } from 'luxon'
import { getAppLang } from 'utils/language'

export const getDate = (timestamp: number) => {
	const appLanguage = getAppLang()
	const dateTime = DateTime.fromMillis(timestamp)
	return dateTime.setLocale(appLanguage).toFormat('dd. MMMM yyyy')
}

export const getTime = (timestamp: number) => {
	const dateTime = DateTime.fromMillis(timestamp)
	return dateTime.toFormat('HH:mm')
}

export const getTimeRange = (
	startTime: number,
	endTime: number,
	pauseStart: number,
	pauseEnd: number,
	isToday: boolean,
	duration: string,
	day: number,
	minimumBookingTime: number
): { [key: number]: string[] } => {
	const timeSlots: { [key: number]: string[] } = {}
	if (!duration || !minimumBookingTime) return undefined as any

	if (startTime === endTime) {
		const timeArray: string[] = []
		for (let i = 0; i < 24 * 60; i += Number(duration)) {
			const currentTime = startTime + i * 60 * 1000
			const dateTime = DateTime.fromMillis(currentTime)
			if (
				(!isToday ||
					(isToday &&
						dateTime.toMillis() >=
							DateTime.now().plus({ hours: minimumBookingTime }).toMillis())) &&
				!isWithinPauseRange(dateTime, pauseStart, pauseEnd)
			) {
				timeArray.push(dateTime.toFormat('HH:mm'))
			}
		}
		timeSlots[day] = timeArray
	} else {
		const timeArray: string[] = []
		let currentTime = startTime

		while (currentTime <= endTime) {
			const dateTime = DateTime.fromMillis(currentTime)

			if (
				(!isToday ||
					(isToday &&
						dateTime.toMillis() >=
							DateTime.now().plus({ hours: minimumBookingTime }).toMillis())) &&
				!isWithinPauseRange(dateTime, pauseStart, pauseEnd)
			) {
				timeArray.push(dateTime.toFormat('HH:mm'))
			}

			currentTime += Number(duration) * 60 * 1000
		}
		timeSlots[day] = timeArray
	}
	return timeSlots
}

const isWithinPauseRange = (dateTime: DateTime, pauseStart: number, pauseEnd: number): boolean => {
	const currentTimeMillis = dateTime.toMillis()
	return currentTimeMillis >= pauseStart && currentTimeMillis <= pauseEnd
}

export const getDay = (timestamp: number) => {
	const appLanguage = getAppLang()
	const dateTime = DateTime.fromMillis(timestamp)
	return dateTime.setLocale(appLanguage).toFormat('cccc')
}

export const isOpen = (startTime: DateTime, endTime: DateTime) => {
	const currentDateTime = DateTime.now()

	return currentDateTime >= startTime && currentDateTime <= endTime
}

export const appendDateTime = (date: string, timeSlot: string) => {
	const dateTime = DateTime.fromJSDate(date as any)

	const timeArray = timeSlot.split(':')
	const hour = parseInt(timeArray[0])
	const minute = parseInt(timeArray[1].split(' ')[0])

	const dateTimeWithTime = dateTime.set({ hour, minute })

	return dateTimeWithTime.toMillis()
}

export const withinCancellationTime = (
	timeDuration: Duration,
	appointmentTime: DateTime
): boolean => {
	const currentTime = DateTime.now()
	const endTime = appointmentTime.plus(timeDuration)
	return currentTime >= appointmentTime && currentTime <= endTime
}

export const convertDayToDateTime = (day: number): DateTime => {
	const today = DateTime.now()
	const referenceDay = today.startOf('week')
	const desiredDay = referenceDay.plus({ days: day })

	return desiredDay
}

export const getDuration = (date: DateTime): string => {
	const end = DateTime.now()

	const diff = end.diff(date, ['years', 'months', 'days', 'hours', 'minutes']).toObject()

	if (diff.years && diff.years > 0) {
		return `${diff.years}y`
	} else if (diff.months && diff.months > 0) {
		return `${diff.months}months`
	} else if (diff.days && diff.days > 0) {
		return `${diff.days}d`
	} else if (diff.hours && diff.hours > 0) {
		return `${diff.hours}h`
	} else if (diff.minutes && diff.minutes > 0) {
		return `${diff.minutes.toFixed(0)}m`
	}

	return ''
}

export const getChatDuration = (timestamp: Timestamp): string => {
	const start =
		timestamp && timestamp.toDate
			? DateTime.fromJSDate(timestamp.toDate(), { zone: 'utc' }).toLocal()
			: DateTime.now()

	const end = DateTime.now()

	const diff = end.diff(start, ['years', 'months', 'days', 'hours', 'minutes']).toObject()

	if (diff.years && diff.years > 0) {
		return `${diff.years}y`
	} else if (diff.months && diff.months > 0) {
		return `${diff.months}months`
	} else if (diff.days && diff.days > 0) {
		return `${diff.days}d`
	} else if (diff.hours && diff.hours > 0) {
		return `${diff.hours}h`
	} else if (diff.minutes && diff.minutes > 0) {
		return `${diff.minutes.toFixed(0)}m`
	}

	return ''
}
