import clsx from 'clsx'

interface CategoryCardProps {
	image: string
	title: string
	className?: string
}

export const CategoryCard = ({ image, title, className }: CategoryCardProps) => {
	return (
		<div
			style={{ boxShadow: '0px 4px 24px 0px #61BC5F4D' }}
			className={clsx('rounded-xl overflow-hidden relative border border-[#CFD8DC]', className)}>
			<img src={image} alt={title} className="object-cover w-full h-full object-center" />
			<div className="absolute bottom-0 inset-x-0 bg-primary/40 max-md:py-1.5 max-md:px-[9px] md:p-6 flex items-center justify-center">
				<h1 className="font-domine font-bold text-[13px] md:text-[22px] text-white text-center">
					{title}
				</h1>
			</div>
		</div>
	)
}
