import { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { AuthOptions } from 'constants/constants'
import { useAppSelector } from 'hooks'
import { useLoadResources } from 'hooks/useLoadResources'
import { Auth } from 'pages/auth'
import { ChangePassword } from 'pages/change-password'
import { Chats } from 'pages/chats'
import { CompanyDetails } from 'pages/company'
import { CompanyAGB } from 'pages/company/agb'
import { Home } from 'pages/home'
import { RelatedPersons } from 'pages/related-persons'
import { CreateOrEditPerson } from 'pages/related-persons/create'
import { MobileSupport } from 'pages/support'
import { UserAppointments } from 'pages/user/appointments'
import { CreateAppointment } from 'pages/user/appointments/create'
import { AppointmentDetails } from 'pages/user/appointments/details'
import { AppointmentError } from 'pages/user/appointments/error'
import { AppointmentSuccess } from 'pages/user/appointments/success'
import { UserDashboard } from 'pages/user/dashboard'
import { UserDocuments } from 'pages/user/documents'
import { EditUser } from 'pages/user/edit'
import { ScrollToTop } from 'utils/scroll-to-top'

const Routing = () => {
	const auth = useAppSelector(state => state.auth)

	useLoadResources()

	useEffect(() => {
		let currentUrl = window.location.href
		const modifiedUrl = currentUrl.replace('www.', '')
		if (modifiedUrl !== currentUrl) {
			window.location.replace(modifiedUrl)
		}
	}, [])

	return (
		<BrowserRouter>
			<ScrollToTop>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/cancel" element={<Auth />} />
					<Route
						path="/auth"
						element={
							<PublicRoute auth={auth}>
								<Auth />
							</PublicRoute>
						}
					/>
					<Route path="user">
						<Route
							index
							element={
								<ProtectedRoute auth={auth}>
									<UserDashboard />
								</ProtectedRoute>
							}
						/>
						<Route path="appointments">
							<Route
								index
								element={
									<ProtectedRoute auth={auth}>
										<UserAppointments />
									</ProtectedRoute>
								}
							/>
							<Route
								path="details/:id"
								element={
									<ProtectedRoute auth={auth}>
										<AppointmentDetails />
									</ProtectedRoute>
								}
							/>
						</Route>
						<Route
							path="documents"
							element={
								<ProtectedRoute auth={auth}>
									<UserDocuments />
								</ProtectedRoute>
							}
						/>
						<Route
							path="edit"
							element={
								<ProtectedRoute auth={auth}>
									<EditUser />
								</ProtectedRoute>
							}
						/>
					</Route>
					<Route
						path="/change-password"
						element={
							<ProtectedRoute auth={auth}>
								<ChangePassword />
							</ProtectedRoute>
						}
					/>
					<Route path="related-persons">
						<Route
							index
							element={
								<ProtectedRoute auth={auth}>
									<RelatedPersons />
								</ProtectedRoute>
							}
						/>
						<Route
							path="create"
							element={
								<ProtectedRoute auth={auth}>
									<CreateOrEditPerson />
								</ProtectedRoute>
							}
						/>
						<Route
							path="edit/:id"
							element={
								<ProtectedRoute auth={auth}>
									<CreateOrEditPerson />
								</ProtectedRoute>
							}
						/>
					</Route>
					<Route
						path="/chats"
						element={
							<ProtectedRoute auth={auth}>
								<Chats />
							</ProtectedRoute>
						}
					/>
					<Route path="/create-appointment" element={<CreateAppointment />} />
					<Route
						path="/appointment-success/:id"
						element={
							<ProtectedRoute auth={auth}>
								<AppointmentSuccess />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/appointment-error/:id"
						element={
							<ProtectedRoute auth={auth}>
								<AppointmentError />
							</ProtectedRoute>
						}
					/>
					<Route path="/company-details/:id" element={<CompanyDetails />} />
					<Route path="/company/:id/agb" element={<CompanyAGB />} />
					<Route path="/support" element={<MobileSupport />} />
				</Routes>
			</ScrollToTop>
		</BrowserRouter>
	)
}

type RouteProps = {
	auth?: AuthState
	redirectPath?: string
	children: JSX.Element
	customRedirect?: boolean
}

const ProtectedRoute = ({
	auth,
	children,
	customRedirect,
	redirectPath = `/auth?tab=${AuthOptions.LOGIN}`
}: RouteProps) => {
	if (!auth?.accessToken || customRedirect) {
		return <Navigate to={redirectPath} replace={true} />
	}

	return children
}

const PublicRoute = ({ auth, children, redirectPath = '/' }: RouteProps) => {
	const [shouldRedirect, setShouldRedirect] = useState(false)

	useEffect(() => {
		if (auth?.accessToken) {
			const timeout = setTimeout(() => {
				setShouldRedirect(true)
			}, 1000)
			return () => clearTimeout(timeout)
		}
	}, [auth])

	if (shouldRedirect) {
		return <Navigate to={redirectPath} replace />
	}

	return children
}

const RedirectToExternal = () => {
	window.location.href = 'https://stylingcorner.ch'
	return null
}

export default Routing
