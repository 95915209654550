import { FaMapMarkerAlt, FaSearch } from 'react-icons/fa'

export const SearchBox = () => {
	return (
		<div
			className="w-full relative z-20 max-w-md mr-auto p-2 rounded-2xl"
			style={{ backgroundImage: 'linear-gradient(to right, #ffbcc6, #d1c4ff)' }}>
			<div className="w-full bg-white p-6 rounded-lg shadow-lg">
				<div className="space-y-4">
					<div className="relative">
						<FaMapMarkerAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
						<input
							type="text"
							placeholder="Where? (city, zip code, address)"
							className="w-full pl-10 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500 bg-[#e9f1f8]"
						/>
					</div>

					<div className="relative">
						<FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
						<input
							type="text"
							placeholder="Company Name, Service, Speciality..."
							className="w-full pl-10 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500 bg-[#e9f1f8]"
						/>
					</div>
				</div>
				<button className="w-full bg-[#5964D3] hover:bg-[#7D8AE6] text-white mt-6 py-3 rounded-lg text-lg focus:outline-none">
					Search
				</button>
			</div>
		</div>
	)
}
