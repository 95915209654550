import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import { Button } from 'components/app/button'
import companyService from 'services/company-service'
import { isOpen } from 'utils/date'
import { getTKey } from 'utils/language'

interface CompanyCardProps {
	id: string
	image: string
	title: string
	address: string
	categories: string[]
}

export const CompanyCard = ({ id, image, title, address, categories }: CompanyCardProps) => {
	const navigate = useNavigate()
	const [companySchedule, setCompanySchedule] = useState<Schedule[]>([])
	useEffect(() => {
		if (id) {
			companyService.getSchedule(id).then(res => setCompanySchedule(res))
		}
	}, [id])

	const mappedSchedule = useMemo(() => {
		let mappedScheduleByDays = [] as { [day: number]: Schedule }
		for (const schedule of companySchedule ?? []) {
			mappedScheduleByDays[schedule.day] = schedule
		}
		return mappedScheduleByDays
	}, [companySchedule])

	const isCompanyOpen = mappedSchedule[DateTime.now().weekday === 7 ? 0 : DateTime.now().weekday]
		? mappedSchedule[DateTime.now().weekday === 7 ? 0 : DateTime.now().weekday]?.type === '24' ||
			isOpen(
				DateTime.fromObject(
					mappedSchedule[DateTime.now().weekday === 7 ? 0 : DateTime.now().weekday].start
				),
				DateTime.fromObject(
					mappedSchedule[DateTime.now().weekday === 7 ? 0 : DateTime.now().weekday].end
				)
			)
		: false

	const { t } = useTranslation()
	const tKey = getTKey('userDashboard')
	return (
		<div className="flex bg-white gap-x-3.5 p-3 card-shadow overflow-hidden">
			<div className="relative">
				<img
					src={image}
					alt="company-image"
					className="rounded h-[100px] max-w-[107px] min-w-[107px] lg:h-[103px] lg:w-[111px] object-cover"
				/>
			</div>
			<div className="flex flex-col gap-y-1.5 grow">
				<div className="flex flex-col gap-y-0.5">
					<div className="flex items-center justify-between">
						<h3 className="text-primary text-sm lg:text-base font-semibold overflow-hidden text-ellipsis line-clamp-1">
							{title}
						</h3>
					</div>
					<div className="flex flex-wrap">
						{categories.slice(0, 2)?.map((category, index) => (
							<div className="text-secondary text-xs" key={category}>
								{index > 0 && ', '}
								{category}
							</div>
						))}
					</div>
					<span className="text-primary text-xs font-semibold">{address}</span>
				</div>
				<div className="flex items-center justify-between">
					<div className="flex items-center gap-x-1">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							className="w-[14px] h-[14px]"
							fill="none">
							<path
								d="M15.4731 16.5269L16.5269 15.4731L12.75 11.6959V6.99998H11.25V12.3038L15.4731 16.5269ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5765 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45268 3.248 8.29655C3.74667 7.1404 4.42342 6.13472 5.27825 5.2795C6.1331 4.42427 7.13834 3.74721 8.29398 3.24833C9.44959 2.74944 10.6844 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2527 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0016 21.5ZM12 20C14.2166 20 16.1041 19.2208 17.6625 17.6625C19.2208 16.1041 20 14.2166 20 12C20 9.78331 19.2208 7.89581 17.6625 6.33748C16.1041 4.77914 14.2166 3.99998 12 3.99998C9.78331 3.99998 7.89581 4.77914 6.33748 6.33748C4.77914 7.89581 3.99998 9.78331 3.99998 12C3.99998 14.2166 4.77914 16.1041 6.33748 17.6625C7.89581 19.2208 9.78331 20 12 20Z"
								fill={isCompanyOpen ? '#4CAF50' : '#D61227'}
							/>
						</svg>
						<span
							className={clsx(
								'text-[10px] lg:text-xs',
								isCompanyOpen ? 'text-[#4CAF50]' : 'text-[#D61227]'
							)}>
							{isCompanyOpen ? t('search.opened') : t('search.closed')}
						</span>
					</div>
					<Button
						onClick={() => navigate(`/company-details/${id}`)}
						className="max-lg:w-[80px] max-lg:text-[10px] py-2 lg:px-3 max-lg:px-0 font-bold">
						{t(tKey('companyCard.appointment'))}
					</Button>
				</div>
			</div>
		</div>
	)
}
