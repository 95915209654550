import { Article1, Article2, Article3 } from 'assets/icons'
import BlogCard from './blog-card'

const ArticleSection = () => {
	const articles = [
		{
			date: '07 May 2024',
			readTime: '2 min read',
			imageUrl: Article1,
			content:
				'With lots of unique blocks, you can easily build a page without coding. Build your next landing page.'
		},
		{
			date: '07 May 2024',
			readTime: '2 min read',
			imageUrl: Article2,
			content:
				'With lots of unique blocks, you can easily build a page without coding. Build your next landing page.'
		},
		{
			date: '07 May 2024',
			readTime: '2 min read',
			imageUrl: Article3,
			content:
				'With lots of unique blocks, you can easily build a page without coding. Build your next landing page.'
		}
	]

	return (
		<section className="py-12 bg-[#E9F1F8]">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<h2 className="text-4xl font-bold text-center text-black mb-4">Our Latest Articles</h2>
				<p className="text-center text-gray-500 mb-12">
					With lots of unique blocks, you can easily build a page without coding.
				</p>
				<div className="flex flex-wrap justify-center gap-8">
					{articles.map((article, index) => (
						<BlogCard
							key={index}
							date={article.date}
							readTime={article.readTime}
							imageUrl={article.imageUrl}
							content={article.content}
						/>
					))}
				</div>
			</div>
		</section>
	)
}

export default ArticleSection
