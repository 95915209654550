import { CheckmarkIcon, OutlineGlobeIcon, OutlineMailIcon, OutlinePhoneIcon } from 'assets/icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import { Link, useParams, useSearchParams } from 'react-router-dom'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import { AppLayout } from 'components/app/layout'
import appointmentService from 'services/appointment-service'
import { socket } from 'sockets/socket-context'
import { getTKey } from 'utils/language'

export const AppointmentSuccess = () => {
	const { id: appointmentId } = useParams() as { id: string }
	const [searchParams, _setSearchParams] = useSearchParams()
	const { t } = useTranslation()

	const tKey = getTKey('appointment.create')

	const [appointment, setAppointment] = useState<Appointment>()

	useEffect(() => {
		if (appointmentId) {
			if (searchParams.get('session_id')) {
				appointmentService.updatePaymentStatus(
					appointmentId,
					searchParams.get('session_id') as string
				)
			}
			appointmentService.getAppointmentById(appointmentId).then(res => {
				setAppointment(res)
				socket.emit('Appointment created', {
					appointment: res
				})
			})
		}
	}, [appointmentId])

	const contactDetails = [
		{ icon: OutlinePhoneIcon, detail: appointment?.id_company?.phone },
		{
			icon: OutlineGlobeIcon,
			detail: appointment?.id_company?.website,
			link: `https://${appointment?.id_company?.website}`
		},
		{
			icon: OutlineMailIcon,
			detail: appointment?.id_company?.email,
			link: `mailto:${appointment?.id_company?.email}`
		}
	]

	return (
		<AppLayout renderDashboardHeader>
			<div className="lg:pt-14 pt-10 pb-24 max-lg:pb-10 flex flex-col gap-y-8 mx-4 lg:mx-24">
				<div className="flex flex-col items-center gap-y-4">
					<img src={CheckmarkIcon} />
					<h3 className="capitalize font-domine font-bold text-[18px]">
						{t('appointment.create.headings.created')}
					</h3>
				</div>
				<div style={{ boxShadow: '0px 4px 24px 0px #7F9AB266' }} className="rounded-3xl p-5 lg:p-6">
					<div className="flex max-lg:flex-col gap-y-6 justify-between items-center">
						<div className="flex basis-full lg:basis-[75%] lg:pr-8 lg:border-r lg:border-[#7F9AB24D] flex-col gap-y-4">
							<h1 className="font-domine max-lg:text-sm text-primary font-bold">
								{t(tKey('headings.bookingDetails'))}:
							</h1>
							<div className="flex flex-col gap-y-2.5">
								<div className="flex max-lg:flex-col gap-2.5 items-center">
									<div className="px-3 py-5 max-lg:w-full flex-1 bg-[#F5F7FA] rounded-xl flex gap-x-3 items-center">
										<h6 className="text-[#757575] text-sm font-semibold">
											{t('appointment.company')}:
										</h6>
										<h4 className="text-primary text-sm font-semibold">
											{appointment?.company_name}
										</h4>
									</div>
									<div className="px-3 py-5 max-lg:w-full flex-1 bg-[#F5F7FA] rounded-xl flex gap-x-3 items-center">
										<h6 className="text-[#757575] text-sm font-semibold">
											{t('appointment.service')}:
										</h6>
										<h4 className="text-primary text-sm font-semibold">
											{appointment?.service_name}
										</h4>
									</div>
									<div className="px-3 py-5 max-lg:w-full flex-1 bg-[#F5F7FA] rounded-xl flex gap-x-3 items-center">
										<h6 className="text-[#757575] text-sm font-semibold">
											{t('appointment.paymentMethod')}:
										</h6>
										<h4 className="text-primary text-sm font-semibold">
											{appointment?.payment_mode === 'onsite'
												? t('appointment.onsite')
												: t('appointment.online')}
										</h4>
									</div>
								</div>

								<div className="flex max-lg:flex-col gap-2.5 items-center">
									<div className="px-3 py-5 max-lg:w-full flex-1 bg-[#F5F7FA] rounded-xl flex gap-x-3 items-center">
										<h6 className="text-[#757575] text-sm font-semibold">
											{t('appointment.time')}:
										</h6>
										{appointment?.from && (
											<h4 className="text-primary text-sm font-semibold">
												{DateTime.fromMillis(appointment.from).toFormat('dd LLL, yyyy  hh:mma')}
											</h4>
										)}
									</div>
									<div className="px-3 py-5 max-lg:w-full flex-1 bg-[#F5F7FA] rounded-xl flex gap-x-3 items-center">
										<h6 className="text-[#757575] text-sm font-semibold">
											{t('editUser.labels.location')}:
										</h6>
										<h4 className="text-primary text-sm font-semibold">{`${appointment?.id_company?.address} ${appointment?.id_company?.address_no}, ${appointment?.id_company?.zip} ${appointment?.id_company?.city}`}</h4>
									</div>
									<div className="px-3 py-5 max-lg:w-full flex-1 bg-[#F5F7FA] rounded-xl flex gap-x-3 items-center">
										<h6 className="text-[#757575] text-sm font-semibold">
											{t('appointment.create.headings.additionalInformation')}:
										</h6>
										<h4 className="text-primary text-sm font-semibold">
											{appointment?.additonal_information}
										</h4>
									</div>
								</div>
							</div>
						</div>
						<div className="basis-full lg:basis-[25%] flex justify-center">
							<div className="flex flex-col items-center gap-y-3.5">
								<div
									style={{ boxShadow: '0px 4.06px 24.34px 0px #12325838' }}
									className="border border-[#CFD8DC80] p-2">
									<QRCode
										className="lg:w-[164px] lg:h-[164px] w-[124px] h-[124px]"
										value={`https://www.pro.stylingcorner.ch/appointment-details/${appointmentId}`}
									/>
								</div>
								<p className="text-[#323232] text-sm text-center">
									{t('companyDetails.bringQRCode')}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="px-6 py-5 rounded-[16px] flex flex-col gap-y-3 bg-[#F5F6F5]">
					<h1 className="font-domine max-lg:text-sm text-primary font-bold">
						{t('companyDetails.contactDetails')}
					</h1>
					<div className="grid grid-cols-1 gap-y-4 lg:grid-cols-3 items-center">
						{contactDetails.map((contact, index) => (
							<React.Fragment key={index}>
								{contact.link ? (
									<Link
										to={contact.link}
										target="__blank"
										className={clsx('flex gap-x-3 items-center', {
											'underline text-[#06BC5F] hover:text-red-500': contact.detail === 'AGB'
										})}>
										<img src={contact.icon} className="h-6 w-6 shrink-0" />
										<span
											className={clsx(
												'max-lg:text-sm',
												contact.detail === 'AGB'
													? 'text-[#06BC5F] hover:text-red-500'
													: 'text-primary'
											)}>
											{contact.detail}
										</span>
									</Link>
								) : (
									<div className="flex gap-x-3 items-center">
										<img src={contact.icon} className="h-6 w-6 shrink-0" />
										<span className="text-primary max-lg:text-sm">{contact.detail}</span>
									</div>
								)}
							</React.Fragment>
						))}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}
