import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
	Article2,
	Category1,
	Category2,
	Category3,
	HomeCarousel1,
	HomeCarousel2,
	HomeCarousel3,
	HomeCarousel4
} from 'assets/icons'
import { ReactComponent as BlueEllipseIcon } from 'assets/svgs/blue-ellipse.svg'
import { ReactComponent as ArrowRightIcon } from 'assets/svgs/cover-arrow.svg'
import { ReactComponent as PinkEllipseIcon } from 'assets/svgs/pink-ellipse.svg'
import clsx from 'clsx'
import { AppLayout } from 'components/app/layout'
import AppointmentSteps from 'components/home/appointment-steps'
import ArticleSection from 'components/home/article-section'
import { FAQ } from 'components/home/faq'
import { Footer } from 'components/home/footer'
import PromotionSection from 'components/home/promotion-section'
import ProvidersSection from 'components/home/provider-section'
import { SearchBox } from 'components/home/search-box'
import { SubscriptionBox } from 'components/home/subscription-box'
import TestimonialSection from 'components/home/testimonial-section'
import WhyChooseSection from 'components/home/why-choose-section'
import { useRef, useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperClass, SwiperRef, SwiperSlide } from 'swiper/react'
import { getTKey } from 'utils/language'

import { CategoryCard } from 'components/cards/category-card'
import useIsMobile from 'hooks/useIsMobile'
import './slider.css'

export const Home = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const swiperRef = useRef<SwiperRef>(null)
	const prevRef = useRef<HTMLDivElement>(null)
	const nextRef = useRef<HTMLDivElement>(null)

	const [isPrevDisabled, setIsPrevDisabled] = useState(true)
	const [isNextDisabled, setIsNextDisabled] = useState(true)
	const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

	const tKey = getTKey('home')
	const companyId = searchParams.get('id')

	const handleBarClick = (index: number) => {
		if (swiperRef.current) {
			swiperRef.current.swiper.slideTo(index)
		}
	}

	return (
		<AppLayout
			onLogoClick={() => navigate(`/company-details/${companyId}`)}
			renderHomeHeader={true}>
			<div className="flex flex-col min-h-screen">
				<div className="flex-grow">
					{!isMobile && (
						<div className="overflow-hidden relative flex max-lg:hidden">
							<div className="flex flex-col bg-coverRectangle basis-3/5 bg-center bg-no-repeat bg-cover items-start px-40 pb-10 justify-between h-full">
								<div className="flex flex-col items-center py-20 gap-[33px] justify-center">
									<div className="flex flex-col relative z-20 gap-3">
										<h5 className="text-[#ED107A] font-semibold italic text-lg">
											{t(tKey('labels.anytime'))}
										</h5>
										<h1 className="text-primary font-domine font-bold text-[56px]">
											{t(tKey('headings.easyBooking'))}
										</h1>
									</div>
									<SearchBox />
								</div>
								<h5
									className="text-2xl text-black"
									dangerouslySetInnerHTML={{ __html: t(tKey('labels.appointmentsBooked')) }}
								/>
							</div>
							<PinkEllipseIcon className="absolute" />
							<BlueEllipseIcon className="absolute translate-x-40 -translate-y-20" />
							<div
								ref={prevRef}
								className={clsx(
									'absolute max-lg:hidden size-10 left-1/2 translate-x-6 top-1/2 transform -translate-y-1/2 flex items-center justify-center py-2 px-3 z-50',
									isPrevDisabled ? 'hidden' : 'cursor-pointer'
								)}>
								<ArrowRightIcon className="rotate-180 shrink-0" />
							</div>
							<div
								ref={nextRef}
								className={clsx(
									'absolute max-lg:hidden size-10 right-14 top-1/2 transform -translate-y-1/2 flex items-center justify-center py-2 px-3 z-10',
									isNextDisabled ? 'hidden' : 'cursor-pointer'
								)}>
								<ArrowRightIcon className="shrink-0" />
							</div>
							<div className="absolute bottom-6 inset-x-0 left-1/2 w-1/2 flex justify-center items-center gap-2.5">
								{Array.from({ length: 4 }).map((_, index) => (
									<div
										key={index}
										onClick={() => handleBarClick(index)}
										className={clsx(
											'h-[3px] w-24 cursor-pointer hover:bg-white',
											index === currentSlideIndex ? 'bg-white' : 'bg-white/50'
										)}></div>
								))}
							</div>

							<div className="basis-2/5 absolute right-0 max-w-full -z-10 top-0">
								<Swiper
									slidesPerView={1}
									loop={false}
									ref={swiperRef}
									modules={[Navigation]}
									navigation={{
										prevEl: prevRef.current,
										nextEl: nextRef.current
									}}
									onBeforeInit={(swiper: SwiperClass) => {
										const params = swiper.params
										const navigation = params.navigation
										// @ts-ignore
										navigation.prevEl = prevRef.current
										// @ts-ignore
										navigation.nextEl = nextRef.current
									}}
									onSlideChange={(swiper: SwiperClass) => {
										setIsPrevDisabled(swiper.isBeginning)
										setIsNextDisabled(swiper.isEnd)
										setCurrentSlideIndex(swiper.activeIndex)
									}}
									onSwiper={(swiper: SwiperClass) => {
										const params = swiper.params
										const navigation = params.navigation
										// @ts-ignore
										navigation.prevEl = prevRef.current
										// @ts-ignore
										navigation.nextEl = nextRef.current
										swiper.navigation.init()
										swiper.navigation.update()
										setIsPrevDisabled(swiper.isBeginning)
										setIsNextDisabled(swiper.isEnd)
									}}>
									<SwiperSlide className="w-full">
										<img src={HomeCarousel1} className="object-cover w-full" />
									</SwiperSlide>
									<SwiperSlide className="w-full">
										<img src={HomeCarousel2} className="object-cover w-full" />
									</SwiperSlide>
									<SwiperSlide className="w-full">
										<img src={HomeCarousel3} className="object-cover w-full" />
									</SwiperSlide>
									<SwiperSlide className="w-full">
										<img src={HomeCarousel4} className="object-cover w-full" />
									</SwiperSlide>
								</Swiper>
							</div>
						</div>
					)}

					{isMobile && (
						<div className="flex flex-col gap-4 lg:hidden">
							<div className="relative bg-[#E9E0F7] flex flex-col items-start px-5 py-10 justify-between">
								<div className="flex flex-col items-center gap-6 justify-center">
									<div className="flex flex-col gap-[23px] items-center relative z-20">
										<h5 className="text-[#ED107A] font-semibold italic">
											{t(tKey('labels.anytime'))}
										</h5>
										<h1 className="text-primary font-domine font-bold text-center text-[36px]">
											{t(tKey('headings.easyBooking'))}
										</h1>
									</div>
									<SearchBox />
									<h5
										className="text-black"
										dangerouslySetInnerHTML={{ __html: t(tKey('labels.appointmentsBooked')) }}
									/>
								</div>
								<PinkEllipseIcon className="absolute size-[90%] left-0" />
								<BlueEllipseIcon className="absolute size-[90%] right-0" />
							</div>

							<div>
								<Swiper
									slidesPerView={1}
									loop={false}
									ref={swiperRef}
									modules={[Navigation]}
									className="relative"
									navigation={{
										prevEl: prevRef.current,
										nextEl: nextRef.current
									}}
									onBeforeInit={(swiper: SwiperClass) => {
										const params = swiper.params
										const navigation = params.navigation
										// @ts-ignore
										navigation.prevEl = prevRef.current
										// @ts-ignore
										navigation.nextEl = nextRef.current
									}}
									onSlideChange={(swiper: SwiperClass) => {
										setIsPrevDisabled(swiper.isBeginning)
										setIsNextDisabled(swiper.isEnd)
										setCurrentSlideIndex(swiper.activeIndex)
									}}
									onSwiper={(swiper: SwiperClass) => {
										const params = swiper.params
										const navigation = params.navigation
										// @ts-ignore
										navigation.prevEl = prevRef.current
										// @ts-ignore
										navigation.nextEl = nextRef.current
										swiper.navigation.init()
										swiper.navigation.update()
										setIsPrevDisabled(swiper.isBeginning)
										setIsNextDisabled(swiper.isEnd)
									}}>
									<div
										ref={prevRef}
										className={clsx(
											'absolute size-6 left-0 translate-x-6 top-1/2 transform -translate-y-1/2 flex items-center justify-center py-2 px-3 z-50',
											isPrevDisabled ? 'hidden' : 'cursor-pointer'
										)}>
										<ArrowRightIcon className="rotate-180 shrink-0 size-10" />
									</div>
									<div
										ref={nextRef}
										className={clsx(
											'absolute size-6 right-0 top-1/2 -translate-x-6 transform -translate-y-1/2 flex items-center justify-center py-2 px-3 z-10',
											isNextDisabled ? 'hidden' : 'cursor-pointer'
										)}>
										<ArrowRightIcon className="shrink-0 size-10" />
									</div>
									<div className="absolute bottom-5 mx-6 inset-x-0 z-10 grid grid-cols-4 justify-center items-center gap-2.5">
										{Array.from({ length: 4 }).map((_, index) => (
											<div
												key={index}
												onClick={() => handleBarClick(index)}
												className={clsx(
													'h-[3px] w-full cursor-pointer hover:bg-white',
													index === currentSlideIndex ? 'bg-white' : 'bg-white/50'
												)}></div>
										))}
									</div>
									<SwiperSlide>
										<img src={HomeCarousel1} />
									</SwiperSlide>
									<SwiperSlide>
										<img src={HomeCarousel2} />
									</SwiperSlide>
									<SwiperSlide>
										<img src={HomeCarousel3} />
									</SwiperSlide>
									<SwiperSlide>
										<img src={HomeCarousel4} />
									</SwiperSlide>
								</Swiper>
							</div>
						</div>
					)}

					<div className="container md:h-[700px] mx-auto py-8 px-5 md:px-[120px] md:py-[100px]">
						<div className="grid grid-rows-2 h-full grid-cols-2 md:grid-cols-3 gap-4 md:gap-8">
							<CategoryCard
								title="Hair Care Salon"
								image={Category1}
								className="max-md:hidden md:row-span-2"
							/>
							<CategoryCard title="Grand Vista Salon" image={Category2} />
							<CategoryCard title="Best deals in town" image={Article2} />
							<CategoryCard title="Nail Care Salon" image={Category3} className="col-span-2" />
						</div>
					</div>
					<div className="mb-8">
						<AppointmentSteps />
					</div>

					<div className="mb-8">
						<ProvidersSection />
					</div>

					<div className="mb-8">
						<WhyChooseSection />
					</div>

					<div className="mb-8">
						<PromotionSection />
					</div>

					<div className="mb-8">
						<TestimonialSection />
					</div>

					<div className="mb-8">
						<ArticleSection />
					</div>

					<div className="mb-8">
						<FAQ />
					</div>

					<div className="mb-8">
						<SubscriptionBox />
					</div>
				</div>

				<Footer />
			</div>
		</AppLayout>
	)
}
