import { TestimonialBackground, TestimonialClient } from 'assets/icons'
import { useState } from 'react'

const testimonials = [
	{
		content:
			'A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed.',
		clientName: 'Aleena James',
		clientTitle: 'CEO Of Apple',
		clientImage: TestimonialClient
	},
	// Add the same testimonial 3 more times to simulate 4 testimonials
	{
		content:
			'A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed.',
		clientName: 'Aleena James',
		clientTitle: 'CEO Of Apple',
		clientImage: TestimonialClient
	},
	{
		content:
			'A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed.',
		clientName: 'Aleena James',
		clientTitle: 'CEO Of Apple',
		clientImage: TestimonialClient
	},
	{
		content:
			'A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed.',
		clientName: 'Aleena James',
		clientTitle: 'CEO Of Apple',
		clientImage: TestimonialClient
	}
]

const TestimonialSection = () => {
	const [currentIndex, setCurrentIndex] = useState(0)

	// Navigate between testimonials
	const handleNavigation = (index: number) => {
		setCurrentIndex(index)
	}

	const currentTestimonial = testimonials[currentIndex]

	return (
		<section
			className="relative bg-cover bg-center bg-no-repeat h-[600px]"
			style={{ backgroundImage: `url(${TestimonialBackground})` }}>
			<div className="absolute inset-0 bg-[#342e4e] bg-opacity-80"></div>

			<div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white px-4">
				<p className="uppercase text-md tracking-wider mb-4">What Clients Say</p>

				<div className="transform text-[200px] text-pink-500 leading-none h-32">“</div>

				<p className="max-w-2xl text-lg leading-relaxed mb-8">{currentTestimonial.content}</p>

				<div className="flex items-center justify-center space-x-4">
					<img
						src={currentTestimonial.clientImage}
						alt="Client"
						className="w-12 h-12 rounded-full border-2 border-white"
					/>

					<div className="text-left">
						<p className="text-md font-bold">{currentTestimonial.clientName}</p>
						<p className="text-sm text-gray-300">{currentTestimonial.clientTitle}</p>
					</div>
				</div>

				<div className="flex items-center justify-between mt-10">
					<div className="flex space-x-2">
						{testimonials.map((_, index) => (
							<div
								key={index}
								onClick={() => handleNavigation(index)}
								className={`w-6 h-2 rounded-lg cursor-pointer ${
									currentIndex === index ? 'bg-white' : 'bg-purple-400'
								}`}></div>
						))}
					</div>
				</div>

				<div className="absolute bottom-5 right-5 text-2xl text-white flex items-center">
					<span>{String(currentIndex + 1).padStart(2, '0')}</span>
					<span className="mx-1 text-gray-400">/</span>
					<span>{String(testimonials.length).padStart(2, '0')}</span>
				</div>
			</div>
		</section>
	)
}

export default TestimonialSection
