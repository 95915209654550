import { FaArrowRight, FaStar } from 'react-icons/fa'

interface ProviderCardProps {
	imageUrl: string
	title: string
	description: string
	category: string
	rating: number
}

const ProviderCard = ({ imageUrl, title, description, category, rating }: ProviderCardProps) => {
	return (
		<div className="w-full mx-auto">
			<div className="relative">
				<img src={imageUrl} alt={title} className="w-full h-40 md:h-40 rounded-full object-cover" />
			</div>
			<div className="p-4">
				<h2 className="text-lg md:text-xl font-semibold text-gray-800">{title}</h2>
				<p className="text-xs md:text-sm text-gray-500 mt-1">{description}</p>
				<div className="flex items-center justify-between mt-4">
					<div className="flex flex-col space-y-2">
						<span className="bg-[#fef1f7] text-purple-600 border border-purple-600 text-xs px-2 py-1 rounded-full">
							{category}
						</span>
						<div className="flex">
							{[...Array(5)].map((_, i) => (
								<FaStar
									key={i}
									className={`w-4 h-4 ${i < rating ? 'text-yellow-300' : 'text-gray-300'}`}
								/>
							))}
						</div>
					</div>

					<button className="bg-[#5964D3] text-white p-2 md:p-3 rounded-full hover:bg-[#7D8AE6] focus:outline-none">
						<FaArrowRight />
					</button>
				</div>
			</div>
		</div>
	)
}

export default ProviderCard
