import { WhyChooseUs1, WhyChooseUs2 } from 'assets/icons'

const WhyChooseSection = () => {
	return (
		<section className="bg-[#b790d9] relative py-12 px-4 md:mx-20 rounded-3xl overflow-hidden">
			<img src={WhyChooseUs1} alt="Decorative Circle" className="absolute top-0 left-0 z-0" />
			<img
				src={WhyChooseUs2}
				alt="Decorative Circle"
				className="absolute bottom-0 right-0 z-0 hidden md:block"
				style={{ right: '250px' }}
			/>

			<div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center relative z-10">
				<div className="flex-1 text-center md:text-left mb-8 md:mb-0">
					<h2 className="text-3xl md:text-5xl font-bold text-white mb-4">
						Why Choose <span className="block mt-2 text-[#2c2d6a]">Styling Corner?</span>
					</h2>
					<p className="text-sm md:text-xl text-white mb-6">
						Discover why Appointo is the best choice for managing your appointments.
					</p>
				</div>

				<div className="flex-1 grid grid-cols-1 gap-6 md:grid-cols-2">
					<div className="bg-white shadow-lg p-6 md:p-8 rounded-xl shadow-[0_4px_10px_rgba(255,255,255,0.3)]">
						<h3 className="text-lg md:text-2xl font-bold text-[#2c2d6a]">
							Flexible Payment Methods
						</h3>
						<p className="text-sm md:text-md text-gray-600 mt-2">
							Enjoy the flexibility of choosing{' '}
							<span className="text-[#e71d73]">pay online with a card</span>, or{' '}
							<span className="text-[#e71d73]">opt for cash</span> on arrival through a secure
							payment gateway.
						</p>
					</div>

					<div className="bg-white shadow-lg p-6 md:p-8 rounded-xl shadow-[0_4px_10px_rgba(255,255,255,0.3)]">
						<h3 className="text-lg md:text-2xl font-bold text-[#2c2d6a]">24/7 Customer Support</h3>
						<p className="text-sm md:text-md text-gray-600 mt-2">
							Our dedicated customer support team is available 24/7 to{' '}
							<span className="text-[#e71d73]">
								help with booking, payments, or navigating the app
							</span>
							. We're just a call away.
						</p>
					</div>

					<div className="bg-white shadow-lg p-6 md:p-8 rounded-xl shadow-[0_4px_10px_rgba(255,255,255,0.3)]">
						<h3 className="text-lg md:text-2xl font-bold text-[#2c2d6a]">Easy Online Booking</h3>
						<p className="text-sm md:text-md text-gray-600 mt-2">
							Our mobile-friendly platform ensures that you have access to{' '}
							<span className="text-[#e71d73]">top-rated providers</span> and services, anywhere,
							anytime.
						</p>
					</div>

					<div className="bg-white shadow-lg p-6 md:p-8 rounded-xl shadow-[0_4px_10px_rgba(255,255,255,0.3)]">
						<h3 className="text-lg md:text-2xl font-bold text-[#2c2d6a]">Secure and Reliable</h3>
						<p className="text-sm md:text-md text-gray-600 mt-2">
							Our platform uses <span className="text-[#e71d73]">advanced encryption</span> and
							security measures to protect your personal and payment information.
						</p>
					</div>
				</div>
			</div>
		</section>
	)
}

export default WhyChooseSection
